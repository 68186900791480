import { Head } from "@/components/Head";
import DocsPage from "@/components/ui/DocsPage";

export const Docs = () => {
  return (
    <>
      <Head description="Welcome to Ketzek" />
      <div className="">
        <DocsPage />
      </div>
    </>
  );
};
