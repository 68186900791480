import { Head } from "@/components/Head";
import BlogPage from "@/components/ui/BlogPage";

export const Blog = () => {
  return (
    <>
      <div className="">
        <Head description="Welcome to Ketzek" />
        <BlogPage />
      </div>
    </>
  );
};
