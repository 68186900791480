import { Head } from "@/components/Head";
import BehaviorPage from "@/components/ui/BehaviorPage";

export const Coding = () => {
  return (
    <>
      <div className="h-screen bg-gradient-to-b from-stone-700 to-orange-900">
        <Head description="Welcome to Ketzek" />
        <BehaviorPage />
      </div>
    </>
  );
};
