import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useAuth } from "@/providers/auth";
import { Button } from "./button";
import { Check, Mic, Power } from "lucide-react";
import { SendHorizonal } from "lucide-react";

declare global {
  interface Window {
    webkitSpeechRecognition: any;
  }
}

const SystemPage = () => {
  const { user } = useAuth();
  const [messages, setMessages] = useState<any>([]);
  const [botTyping, setBotTyping] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [interviewId, setInterviewId] = useState<string>("");
  const [results, setResults] = useState<any>(null);

  const recognitionRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  const prompts = [
    ["hi", "hey", "hello", "good morning", "good afternoon"],
    // ... (other prompts)
  ];

  const replies = [
    ["Hello!", "Hi!", "Hey!", "Hi there!", "Howdy"],
    // ... (other replies)
  ];

  const alternative = [
    "Same",
    "Go on...",
    "Bro...",
    "Try again",
    "I'm listening...",
    "I don't understand :/",
  ];

  const coronavirus = [
    "Please stay home",
    "Wear a mask",
    "Fortunately, I dont have COVID",
    "These are uncertain times",
  ];

  const compare = (promptsArray, repliesArray, string) => {
    let reply;
    let replyFound = false;
    for (let x = 0; x < promptsArray.length; x++) {
      for (let y = 0; y < promptsArray[x].length; y++) {
        if (promptsArray[x][y] === string) {
          let replies = repliesArray[x];
          reply = replies[Math.floor(Math.random() * replies.length)];
          replyFound = true;
          break;
        }
      }
      if (replyFound) {
        break;
      }
    }
    if (!reply) {
      for (let x = 0; x < promptsArray.length; x++) {
        for (let y = 0; y < promptsArray[x].length; y++) {
          if (levenshtein(promptsArray[x][y], string) >= 0.75) {
            let replies = repliesArray[x];
            reply = replies[Math.floor(Math.random() * replies.length)];
            replyFound = true;
            break;
          }
        }
        if (replyFound) {
          break;
        }
      }
    }
    return reply;
  };

  const levenshtein = (s1, s2) => {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  };

  const editDistance = (s1, s2) => {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  };

  const toggleListening = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const startListening = () => {
    if ("webkitSpeechRecognition" in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = "en-US";

      recognition.onstart = () => {
        setIsListening(true);
      };
      recognition.onresult = (event: any) => {
        for (let i = event.resultIndex; i < event.results.length; i++) {
          if (event.results[i].isFinal) {
            addChat("user", event.results[i][0].transcript);
          }
        }
      };

      recognitionRef.current = recognition;
      recognition.start();
    } else {
      alert("Your browser does not support speech recognition.");
    }
  };

  const stopListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  };

  const simulateBotResponse = (message) => {
    setBotTyping(false);
    setMessages((prevMessages) => [...prevMessages, { from: "bot", text: message }]);
    scrollChat();
  };

  const addChat = (from, text) => {
    const newTranscript = transcript + text + " ";
    setTranscript(newTranscript);
    setMessages((prevMessages) => [...prevMessages, { from, text }]);

    continueInterview(newTranscript);
  };

  const updateChat = (input) => {
    if (inputRef.current.value.trim()) {
      addChat("user", inputRef.current.value.trim());
      inputRef.current.value = "";
    }
  };

  const startInterview = async () => {
    try {
      const data = {
        email: user?.email,
        session: user?.session,
        level: "4",
        interview_type: "behavior",
      };
      const response = await axios.post("https://api.ketzek.com/startBehavioralInterview/", data);
      setInterviewId(response.data?.interview_id);
    } catch (error) {
      console.error(error);
    }
  };

  const continueInterview = async (transcript) => {
    try {
      const data = {
        email: user?.email,
        session: user?.session,
        interview_id: interviewId,
        user_input: transcript,
      };
      setBotTyping(true);
      const response = await axios.post("https://api.ketzek.com/continueBehavioralInterview/", data);
      simulateBotResponse(response.data?.reply);
    } catch (error) {
      console.error(error);
    }
  };

  const endInterview = async () => {
    try {
      const data = {
        email: user?.email,
        session: user?.session,
        interview_id: interviewId,
        user_input: transcript,
      };
      setBotTyping(true);
      const response = await axios.post("https://api.ketzek.com/endBehavioralInterview/", data);
      simulateBotResponse(response.data?.reply);
      setResults(response.data?.reply);
      setInterviewId("");
    } catch (error) {
      console.error(error);
    }
  };

  const scrollChat = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight - messagesContainerRef.current.clientHeight;
      setTimeout(() => {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop =
            messagesContainerRef.current.scrollHeight - messagesContainerRef.current.clientHeight;
        }
      }, 100);
    }
  };

  useEffect(() => {
    scrollChat();
  }, [messages, botTyping]);

  return (
    <div className="px-2 py-6 mx-auto bg-transparent sm:px-6 max-w-7xl">
      <div className="flex justify-end px-2 space-x-2">
        <Button
          onClick={startInterview}
          disabled={!!interviewId}
          size="sm"
          variant="default"
          className="flex items-center gap-1.5"
        >
          <Power size={14} /> Start
        </Button>
        <Button
          onClick={endInterview}
          disabled={!interviewId}
          size="sm"
          variant="default"
          className="flex items-center gap-1.5"
        >
          <Check size={20} /> Finish
        </Button>
      </div>
      <div className="flex flex-col justify-between flex-1 h-[85vh]">
        <div
          id="messages"
          ref={messagesContainerRef}
          className="flex flex-col p-2 mt-6 space-y-4 overflow-y-auto scrolling-touch scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrollbar-hide"
        >
          {messages.map((message: any, key) => (
            <div key={key}>
              <div className={`flex items-end ${message.from === "bot" ? "" : "justify-end"}`}>
                <div
                  className={`flex flex-col space-y-2 text-sm sm:text-base leading-tight max-w-lg mx-2 ${
                    message.from === "bot" ? "order-2 items-start" : "order-1 items-end"
                  }`}
                >
                  <div>
                    <span
                      className={`px-4 py-3 rounded-xl inline-block ${
                        message.from === "bot"
                          ? "rounded-bl-none text-white border-redk border-[1.5px]"
                          : "rounded-br-none text-white border-redk border-[1.5px]"
                      }`}
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    />
                  </div>
                </div>
                <img
                  src={
                    message.from === "bot"
                      ? "https://cdn.icon-icons.com/icons2/1371/PNG/512/robot02_90810.png"
                      : "https://i.pravatar.cc/100?img=7"
                  }
                  alt=""
                  className={`w-6 h-6 rounded-full ${
                    message.from === "bot" ? "order-1" : "order-2"
                  }`}
                />
              </div>
            </div>
          ))}
          <div style={{ display: botTyping ? "flex" : "none" }}>
            <div className="flex items-end gap-2">
              <img
                src={"https://cdn.icon-icons.com/icons2/1371/PNG/512/robot02_90810.png"}
                alt=""
                className={`w-6 h-6 rounded-full`}
              />
              <div className="flex items-end px-3 py-2 bg-gray-800 rounded-full">
                <div className="ticontainer">
                  <div className="flex items-center h-[20px]">
                    <div className="bg-gray-400 tidot"></div>
                    <div className="bg-gray-400 tidot"></div>
                    <div className="bg-gray-400 tidot"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 pt-4 mb-2 border-t-2 border-transparent sm:mb-0">
          <div className="relative flex">
            <input
              type="text"
              placeholder="Say something..."
              autoComplete="off"
              // autoFocus={true}
              onKeyDown={(e) => e.key === "Enter" && updateChat(inputRef.current)}
              className="w-full py-4 pl-5 pr-24 rounded-lg text-white placeholder-gray-300 bg-[#161618] text-md focus:outline-none"
              ref={inputRef}
            />

            <div className="absolute inset-y-0 flex items-center right-4">
              <button
                type="button"
                className="inline-flex items-center justify-center w-8 h-8 text-white transition duration-200 ease-in-out bg-blue-500 rounded-full hover:bg-blue-600 focus:outline-none"
                onClick={() => updateChat(inputRef.current)}
              >
                <SendHorizonal size={20} />
              </button>

              <button
                type="button"
                className={`inline-flex items-center justify-center w-8 h-8 text-white ml-2 transition duration-200 ease-in-out ${
                  isListening ? "bg-red-500" : "bg-green-500"
                } rounded-full hover:bg-red-600 focus:outline-none`}
                onClick={toggleListening}
              >
                <Mic size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemPage;
