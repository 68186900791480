import React from "react";
import { Chart } from "react-google-charts";



function PerformanceChart() {
    const data = [
        ["Date", "Score"],
        [0, 65],
        [1, 72],
        [2, 45],
        [3, 94],
        [4, 94],
        [5, 94],
        [6, 94],
        [7, 94],
        [8, 94],
        [9, 94],
      ];
      
    const options = {
        title: "Performance History",
        titleTextStyle: { color: "#fff", fontName: "monospace" },
        backgroundColor: "#333",
        hAxis: {
          textStyle: { color: '#FFF', fontName: "monospace" },
          titleTextStyle: { color: '#FFF', fontName: "monospace" },
          gridlines: { color: 'transparent' }
        },
        vAxis: {
          viewWindow: {
            max: 100,
          },
          maxValue: 100, 
          textStyle: { color: '#FFF', fontName: "monospace" },
          titleTextStyle: { color: '#FFF', fontName: "monospace" },
          gridlines: { color: 'transparent' }
        },
        legend: { textStyle: { color: '#FFF', fontName: "monospace" } },
        chartArea: {
          backgroundColor: {
            fill: '#333',
            opacity: 100
          }
        },
        curveType: 'function', // Optional: add this line for smooth curves
      };

  return (
    <Chart
      chartType="LineChart"
      width="80%"
      height="400px"
      data={data}
      options={options}
    />
  );
}

export default PerformanceChart;
