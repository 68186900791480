import { Head } from "@/components/Head";
import LandingPage from "@/components/ui/LandingPage";

export const Landing = () => {
  return (
    <>
      <Head description="Welcome to Ketzek" />
      <div className="">
        <LandingPage />
      </div>
    </>
  );
};
