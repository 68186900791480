import {
  AuthUser,
  LoginCredentialsDTO,
  LoginResponse,
  RegisterCredentialsDTO,
  loginWithEmailAndPassword,
  registerWithEmailAndPassword,
} from "@/features/auth";
import storage from "@/lib/storage";
import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

type AuthContextType = {
  user: AuthUser | null;
  login: (data: LoginCredentialsDTO) => Promise<AuthUser | null>;
  register: (data: RegisterCredentialsDTO) => Promise<AuthUser | null>;
  logout: () => void;
  message: string;
  loadUser: () => Promise<AuthUser | null>;
};

const AuthContext = createContext<AuthContextType>({
  user: null as AuthUser | null,
  login: (data: LoginCredentialsDTO) => Promise.resolve({} as AuthUser),
  register: (data: RegisterCredentialsDTO) => Promise.resolve({} as AuthUser),
  logout: () => {},
  message: "",
  loadUser: () => Promise.resolve({} as AuthUser),
});

const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<AuthUser | null>(storage.getUser() || null);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  async function loadUser(): Promise<AuthUser | null> {
    const user = storage.getUser();
    if (user) {
      // const data = await getUser();
      // return data;

      return user;
    }
    return null;
  }

  async function login(data: LoginCredentialsDTO) {
    try {
      setMessage("");
      const response: LoginResponse = await loginWithEmailAndPassword(data);
      const user: AuthUser = {
        email: data.email,
        session: response.session_id,
      };
      storage.setUser(user);
      setUser(user as AuthUser);
      navigate("/dashboard");
      return user;
    } catch (error: any) {
      const errorString = error.response?.data.error || "An error occurred";
      setMessage(errorString);
      return null;
    }
  }

  async function register(data: RegisterCredentialsDTO) {
    try {
      setMessage("");
      const response = await registerWithEmailAndPassword(data);
      setMessage(`User Created!`);
      return {
        email: data.email,
        session: response.session_id,
      };
    } catch (error: any) {
      const errorString = error.response?.data.error || "An error occurred";
      setMessage(errorString);
      return null;
    }
  }

  async function logout() {
    storage.clearUser();
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        register,
        message,
        loadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
