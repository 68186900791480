import { axios } from "@/lib/axios";

import { RegisterResponse } from "../types";

export type RegisterCredentialsDTO = {
  email: string;
  password: string;
};

export const registerWithEmailAndPassword = (
  data: RegisterCredentialsDTO
): Promise<RegisterResponse> => {
  return axios.post("/createUser/", data);
};
