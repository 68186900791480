import { useRoutes } from "react-router-dom";

import { Landing } from "@/features/misc/routes/Landing";
import { Login } from "@/features/misc/routes/Login";
import { Dashboard } from "@/features/misc/routes/Dashboard";
import { Blog } from "@/features/misc/routes/Blog";
import { Behavior } from "@/features/misc/routes/Behavior";
import { Coding } from "@/features/misc/routes/Coding";
import { System } from "@/features/misc/routes/System";
import { Docs } from "@/features/misc/routes/Docs";
import { ContentLayout } from "@/components/Layout";

export const AppRoutes = () => {
  const commonRoutes = [
  { path: "/", element: <Landing /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/login", element: <Login /> },
  { path: "/blog", element: <Blog /> },
  { path: "/system", element: <System /> },
  { path: "/behavior", element: <Behavior /> },
  { path: "/coding", element: <Coding /> },
  { path: "/docs", element: <Docs /> }];

  const element = useRoutes([...commonRoutes]);

  return (
    <>
      <ContentLayout>{element}</ContentLayout>
    </>
  );
};
