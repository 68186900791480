import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Button } from "./button";
import { HeroSection } from "@/components/HeroSection";
import { FeatureSection } from "../FeatureSection";

function LandingPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedSession = localStorage.getItem("session");

    if (storedEmail || storedSession) {
      navigate("/dashboard", {
        state: {
          userData: {
            email: storedEmail,
            session: storedSession,
          },
        },
      });
    }
  }, [navigate]);

  return (
    <div>
      <HeroSection />
      <FeatureSection />
      {/* <div className="flex flex-col items-center justify-center h-screen p-4 text-center">
        <p className="mb-6 text-lg text-red-100">
          Simply the best interview performance tool on the internet
        </p>
        <Button asChild variant="default" size="lg">
          <Link to="/login">Get started</Link>
        </Button>
      </div>
      <div className="header_background-image-wrapper">
        <img
          src="https://assets-global.website-files.com/62e3ee10882dc50bcae8d07a/633ef78628c4c77eb02cea8c_orange-red-gradient-background.png"
          loading="lazy"
          alt=""
          className="header_background-image"
        />
      </div> */}
    </div>
  );
}

export default LandingPage;