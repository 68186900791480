import { AuthUser } from "@/features/auth";

const storagePrefix = "ketzek_ai_";

const storage = {
  getUser: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}user`) as string) as AuthUser;
    } catch (err) {
      return null;
    }
  },
  setUser: (user: AuthUser) => {
    window.localStorage.setItem(`${storagePrefix}user`, JSON.stringify(user));
  },
  clearUser: () => {
    window.localStorage.removeItem(`${storagePrefix}user`);
  },
};

export default storage;
