import { Footer } from "@/features/Footer";
import NavBar from "@/features/NavBar";

export const ContentLayout = ({ children }: any) => {
  return (
    <div className="flex flex-col min-h-screen">
      <NavBar />
      <div className="flex-grow">
        <main className="flex flex-col">{children}</main>
      </div>
      <Footer />
    </div>
  );
};
