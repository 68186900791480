import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "@/routes";
import { HelmetProvider } from "react-helmet-async";
import AuthProvider from "@/providers/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // Initialize Firebase
    const firebaseConfig = {
      apiKey: "AIzaSyAMi18G5KEUUJ8B9ySe-sslHr9o0UPXNLY",
      authDomain: "ketzek-d3d19.firebaseapp.com",
      projectId: "ketzek-d3d19",
      storageBucket: "ketzek-d3d19.appspot.com",
      messagingSenderId: "922225638025",
      appId: "1:922225638025:web:4c0ffe7535c8cb0b75a458",
      measurementId: "G-QK3WGVWKR3"
    };

    const app = initializeApp(firebaseConfig);
    getAnalytics(app);

    const messaging = getMessaging(app);
    requestPermission(messaging);
  }, []);

  async function requestPermission(messaging) {
    console.log('Requesting permission...');
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        await retrieveToken(messaging);
      }
    } catch (error) {
      console.error("Error requesting permission:", error);
    }
  }

  async function retrieveToken(messaging) {
    try {
      const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
      if (currentToken) {
        console.log("Got token:", currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (error) {
      console.error('Error retrieving token:', error);
    }
  }

  return (
    <HelmetProvider>
      <Router>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
