import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from "@/providers/auth";
import PerformanceChart from '@/components/ui/PerformanceChart';
interface AuthInfo {
  email: string;
  session_id: string;
}

function DashboardPage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>(null);

  const getUserInfo = async () => {
    try {
      const response = await axios.post<any>("https://api.ketzek.com/getUserInfo/", user);
      setUserData(response.data?.user_info);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const errorString = error.response?.data.error || "An error occurred";
      return null;
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    console.log(getUserInfo());
  }, []);

  const Card: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => {
    return (
      <div className="p-6 mb-5 text-white bg-gray-800 rounded-lg shadow-lg">
        <h2 className="mb-4 text-xl font-bold">{title}</h2>
        {children}
      </div>
    );
  };

  const UserProfile: React.FC = () => {
    return (
      <div>
      <div className="flex flex-col gap-4 p-4 md:flex-row">
        <Card title="">
        <p>
            {localStorage.getItem("email")}
            <br />
            {localStorage.getItem("session")}
          </p>
          {userData}
         
        </Card>
        <div className="flex flex-col gap-4 p-4 md:flex-row">
          <Card title="Performance History"> <PerformanceChart /></Card>
        </div>
        
        {/* ... Other cards */}
      </div>
      <div>
          <Card title="Interview Now">
            {/* Languages here */}
            <button className="px-4 py-2 text-lg font-bold text-white transition duration-300 ease-in-out transform bg-green-500 rounded-full hover:bg-green-600 hover:scale-110">
              Interview Now 2
            </button>
          </Card>
          <Card title="PastInterviews">{/* Languages here */}</Card>
        </div>
      </div>
    );
  };
  return (
    <div className="flex items-center justify-center h-screen">
      <UserProfile />
    </div>
  );
}

export default DashboardPage;