import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center font-normal justify-center whitespace-nowrap rounded-md relative text-white rounded bg-opacity-40 hover:opacity-75 focus:outline-none border-[1.5px] transition-opacity duration-200 ease-in-out disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-redk text-black100 border-redk",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border-redk",
        qualified: "bg-qualified border-qualified",
        ghost: "text-white border-white",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "p-[0.5rem] px-[1.25rem] py-[0.35rem] text-[.875rem]",
        sm: "px-[12px] py-[6px] sm:px-[16px] sm:py-[8px]",
        lg: "px-[32px] py-[13px]",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
