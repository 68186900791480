import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Particles } from "@/components/Particles";
import React, { useState, useEffect } from 'react';
import { axios } from "@/lib/axios";

export const HeroSection = () => {

  const [showBeta, setShowBeta] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const showJoinBeta = () => {
    console.log('Hey')
    setShowBeta(true);
  }

  const joinBetaClick = (event) => {
    event.preventDefault();
    console.log('Hey')
    joinBeta();
  }

  const joinBeta = async () => {
    try {
      const data = {
        "email": email,
        "name": name,
        "notes": notes
      };
      const endpoint = "https://api.ketzek.com/joinBeta/"
      const response = await axios.post<any>(endpoint, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const errorString = error.response?.data.error || "An error occurred";
      return null;
    }
  };

  return (
    <>
      <Particles className="absolute inset-0 -z-10" />
      <div className="relative isolate pt-14">
        <div
          className="absolute inset-x-0 top-0 overflow-hidden -z-10 transform-gpu blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-orangek to-redk opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="py-24 sm:py-32 lg:pb-40">
          <div className="px-6 mx-auto max-w-7xl lg:px-8">
            <div className="mx-auto text-center">
              <h1 className="text-5xl leading-[1.2] max-w-none font-medium tracking-tight text-white sm:text-[80px] sm:leading-[1.1]">
                <div className="text-transparent bg-gradient-to-r from-orangek to-redk bg-clip-text">
                  Designed
                </div>
                for System Design
              </h1>
              <div className="p-[.25rem]" />
              <p className="max-w-2xl mx-auto mt-6 font-mono text-[1.25rem] leading-8 text-white">
                You can improve what you can measure. Become a top 1% engineer by finding your gaps
                and making progress on concrete metrics.
              </p>
              <p className="max-w-2xl mx-auto mt-6 font-mono text-[1.25rem] leading-8 text-white">
                Created and refined by Senior Engineers at Google, Amazon and Meta, who've mastered
                the process.
              </p>
              <div className="flex items-center justify-center mt-8 gap-x-6">
                <Button variant="default" size="lg" onClick={showJoinBeta}>
                  Join the beta
                </Button>
                
              </div>
              { showBeta &&
              <form
                  className="px-8 w-md pt-20 pb-20 mt-10 mb-8 bg-opacity-50 rounded shadow-md bg-black95"
                  onSubmit={joinBetaClick}
                >
                  <div>
                    <input
                      className="block w-full px-4 py-3 mb-5 leading-tight text-gray-700 bg-[#27272f] border border-[#3a3a3c] rounded-t appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      id="email"
                      type="text"
                      placeholder="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                      className="block w-full px-4 py-3 mb-5 leading-tight text-gray-700 bg-[#27272f] border border-[#3a3a3c] rounded-t appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      id="name"
                      type="text"
                      placeholder="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <input
                      className="block w-full px-4 py-10 mb-5 leading-tight text-gray-700 bg-[#27272f] border border-[#3a3a3c] rounded-t appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      id="notes"
                      type="text"
                      placeholder="Goals/notes(optional)"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    <Button variant="default" size="default" type="submit" className="w-full">
                      Join the waitlist now!
                    </Button>
                  </div>
                </form>
                }
            </div>
            {/* <img
              src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
              alt="App screenshot"
              width={2432}
              height={1442}
              className="mt-16 shadow-2xl rounded-xl bg-white/5 sm:mt-24"
            /> */}
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-orangek to-redk opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </>
  );
};
