import { HighlighterItem, Highlighter } from "@/components/Highlighter";
import { Particles } from "@/components/Particles";

const stats = [
  { id: 1, name: "Interview Bits", value: "750+" },
  { id: 2, name: "Total Beta Testes", value: "150+" },
  { id: 3, name: "Testimonials", value: "0*" },
  // { id: 1, name: "Bits completed every 24 hours", value: "750+" },
  // { id: 2, name: "Total hours users have spent learning", value: "100k+" },
  // { id: 3, name: "Our community", value: "12K+" },
];

export const FeatureSection = () => {
  return (
    <>
      <div className="px-6 mx-auto max-w-7xl lg:px-8 relative">
        <div
          className=" absolute left-0 top-0 -z-10 w-screen h-80
         -mt-24 "
        >
          <Particles className="absolute inset-0 -z-10" quantity={20} staticity={30} />
        </div>
        <div>
          <div className="mx-auto text-center">
            <h1 className="text-4xl tracking-tight text-white !leading-[1.3] font-light max-w-none sm:text-6xl">
              Rapid
              <br />
              Learning
            </h1>
            <div className="p-[.25rem]" />
            <p className="max-w-2xl mx-auto mt-6 font-sans text-[1.25rem] leading-8 text-white">
              Learning has changed. We've designed rapid learning tools that allow you to make
              progress in as little as 5 minute segments.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="p-[40px] sm:p-[64px] rounded-xl bg-gradient-to-r from-[#b07157] to-[#b93d3e] mt-12">
            <dl className="grid content-center grid-cols-1 text-center gap-x-8 gap-y-16 lg:grid-cols-3">
              {stats.map((stat) => (
                <div key={stat.id} className="flex flex-col max-w-xs mx-auto gap-y-4">
                  <dt className="text-base leading-7 text-white">{stat.name}</dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <Highlighter className="flex flex-col justify-center gap-6 lg:flex-row">
            <HighlighterItem>
              <div className="p-[40px] sm:p-[64px] rounded-xl w-full bg-black95">
                <div className="flex flex-col gap-6">
                  <div className="text-3xl font-medium">
                    Start somewhere. The Dopamine will follow
                  </div>
                  <div className="font-sans text-base leading-6 tracking-wider">
                    Start by spending just 5 minutes on a learning bit. We immediately generate
                    metrics from this that will propel you to want to improve.
                  </div>
                </div>
              </div>
            </HighlighterItem>

            <HighlighterItem>
              <div className="p-[40px] sm:p-[64px] rounded-xl w-full bg-black95">
                <div className="flex flex-col gap-6">
                  <div className="text-3xl font-medium">Coding. System Design. Behavioral?</div>
                  <div className="font-sans text-base leading-6 tracking-wider">
                    Have an interview coming up and you don't know where you stand? We've got you
                    covered.
                  </div>
                </div>
              </div>
            </HighlighterItem>
          </Highlighter>
        </div>
      </div>
    </>
  );
};
