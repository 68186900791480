import { Head } from "@/components/Head";
import LoginPage from "@/components/ui/LoginPage";
export const Login = () => {
  return (
    <>
      <div className="">
        <Head description="Welcome to Ketzek" />
        <LoginPage />
      </div>
    </>
  );
};
