import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function DocsPage() {
	const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-col items-center justify-center h-screen p-4 text-center">
        <h1 className="mb-4 text-5xl font-bold text-white"></h1>
        <p className="mb-1 text-lg text-red-100">
          Simply the best interview performance tool on the internet
        </p>
        <a
          type="button"
          href={"/login"}
          className="relative px-5 py-1 text-white bg-orange-500 rounded bg-opacity-40 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600"
          onClick={() => {}}
        >
          <span className="sr-only">Sign in</span>
          Get Started Today
        </a>
      </div>
    </div>
  );
}

export default DocsPage;