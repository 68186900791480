import { Head } from "@/components/Head";
import DashboardPage from "@/components/ui/DashboardPage";

export const Dashboard = () => {
  return (
    <>
      <div className="">
        <Head description="Welcome to Ketzek" />
        <DashboardPage />
      </div>
    </>
  );
};
