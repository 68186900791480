import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import fullNameLogo from "@/full-name-bg-white.png";
import { Button } from "./button";
import { useAuth } from "@/providers/auth";

// Define TypeScript interface for the user data you expect to receive
interface UserData {
  email: string;
  session_id: string;
}

function LoginPage() {
  const { user, login, register, message } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState<string>("text");

  useEffect(() => {
    if (user) navigate("/dashboard");
  }, [user]);

  const handleSignUp = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    await register({ email, password }).then((userData) => {});
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await login({ email, password }).then((userData) => {
      navigate("/dashboard", { state: { userData } });
    });
  };

  return (
    <div className="flex items-center justify-center height-screen">
      <div className="w-full max-w-md">
        <form
          className="px-8 pt-20 pb-20 mt-10 mb-8 bg-opacity-50 rounded shadow-md bg-black95"
          onSubmit={handleSubmit}
        >
          <div className="flex items-center justify-center flex-shrink-0 mb-4">
            <a href={"/"}>
              <img className="w-auto h-16" src={fullNameLogo} alt="Ketzek" onClick={() => {}} />
            </a>
          </div>
          {message && (
            <div className="p-2 mb-4 text-xs text-white border rounded bg-[#C05048]">{message}</div>
          )}
          <div>
            <input
              className="block w-full px-4 py-2 mb-0 leading-tight text-gray-700 bg-[#27272A] border border-[#3a3a3c] rounded-t appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              id="email"
              type="text"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
              className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#27272A] border border-t-0 border-[#3a3a3c] rounded-b appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              id="password"
              type="password"
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between mt-6">
            <Button variant="default" size="default" type="submit" className="w-full">
              Sign In
            </Button>
          </div>
          <div className="mt-4">
            <Button variant="outline" size="default" onClick={handleSignUp} className="w-full">
              Sign Up
            </Button>
          </div>
        </form>
      </div>
      <div className="header_background-image-wrapper">
        <img
          src="https://assets-global.website-files.com/62e3ee10882dc50bcae8d07a/633ef78628c4c77eb02cea8c_orange-red-gradient-background.png"
          loading="lazy"
          alt=""
          className="header_background-image"
        />
      </div>
    </div>
  );
}

export default LoginPage;
